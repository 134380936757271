(function () {
	$.fn.comboForms = function (config) {

		var config = $.extend({
			itemCls: '.form-group',
			mapObj: {},
			grid: '.js-feature-grid',
			featureGrid: '.js-feature-grid .feature',
			defaultProduct: []
		}, config);

		function addOptions(select, obj, key) {

			var $select = $(select);

			if (obj.hasOwnProperty(key)) {

				var selectedVal = $select.val(); //TODO add selection

				$select.find('option').remove();

				for (var i in obj[key]) {

				    var text = obj[key][i].hasOwnProperty("name") ? obj[key][i]["name"] : i;
				    var selected = obj[key][i].default;
				    var minQty = obj[key][i].minQty;
				    var qty = obj[key][i].qty;

				    $select.append('<option value=' + i
                        + (selected ? " selected" : "")
                        + (minQty ? " data-min-qty=" + minQty : "")
                        + (qty ? " data-qty=" + qty : "")
                        + '>' + text + '</option>').data({})

				}

				$select.trigger('change');
			}
		}

		function sliceObj ($combos, obj) {
			var slice = obj;

			$combos.each(function(){
				var value = $(this).val().toString(),
					filter = $(this).data('filter').add;

				if(slice.hasOwnProperty(value) && slice[value].hasOwnProperty(filter))
					slice = slice[value][filter];
			});

			return slice;
		}

		function idFilter (obj,key,cls,grid) {


			if (!obj.hasOwnProperty(key))
				return

			var featureArr = obj[key],
				$grid = $(grid);

			$grid.removeClass(cls);

			if (featureArr=="all") {

				$grid.addClass(cls);

			} else if (featureArr.indexOf('-') > -1) {

				var exceptionArr = featureArr.slice(featureArr.indexOf('-'),featureArr.length),
					filterEl;

				for (var i=0; i <  exceptionArr.length; i++) {
					filterEl = filterEl + (', #'+key+featureArr[i]);
				}

				$grid.not(filterEl).addClass(cls);

			} else {
				for (var i=0; i <  featureArr.length; i++) {
					$('#'+key+featureArr[i]).addClass(cls);
				}
			}

		}


		function insertValue (target,obj,key) {

			if (obj.hasOwnProperty(key) && target.length > 0)
				target.text(obj[key]);

		}


		return this.each(function () {

			var $el = {
				combo : $(this).find('select')
			}

			$el.combo.each(function(i,obj){

				if(config.defaultProduct.length > 0)
					$(this).val(config.defaultProduct[i]);

				$(this).on("change", function () {

				    if (!$(this).val())
				        return

				    var value = $(this).val().toString(),
						add = $(this).data('filter').add,
						feature = $(this).data('filter').feature,
						insert = $(this).data('filter').insert,
						prevCombo = $el.combo.slice(0, i),
						nextCombo = $el.combo.eq(i + 1),
						parentObj = sliceObj(prevCombo, config.mapObj),
						filteredObj = parentObj.hasOwnProperty(value) ? parentObj[value] : {},
                        option = $(this).find('option[value="' + value + '"]'),
                        minQty = option.data('min-qty'),
                        qty = option.data('qty');

				    if (add)
				        addOptions(nextCombo, filteredObj, add);

				    if (feature)
				        idFilter(filteredObj, 'feature', feature, config.featureGrid);

				    if (insert)
				        insertValue($(config.grid).find("." + insert + ' td'), filteredObj, insert);

				    if (minQty)
				        $('#qty').attr('min', minQty);

				    if (qty)
				        $('#qty').val(qty);

				});


				$(this).trigger('change');

			});

		});
	}
})(jQuery);


