
(function() {
    $.fn.curvedSection = function(config) {

        var config = $.extend({
            radius : [0,120,700],
            radiusUnits: 'px',
            hradius: "90%",
            spacing: [120,200],
        },config);

        return this.each(function(){

            var back = $(this),
                middle = back.children(),
                front = middle.children(),
                toRight = front.css("border-top-right-radius")

            $(window).on("scroll",function(){

                var top = back.offset().top - $(window).scrollTop();

                var relativeTop = Math.round(top * 100 / $(window).height()) - 7;

                if (relativeTop < 0 || relativeTop > 100 )
                    return

                if(toRight != "0px"){
                    if(config.radius[0])
                        back.css({"border-top-right-radius":config.hradius +" " + Math.round(relativeTop * config.radius[0] / 100)  + config.radiusUnits})

                    if(config.radius[1])
                        middle.css({'border-top-right-radius':config.hradius + " " + Math.round(relativeTop * config.radius[1] / 100)  + config.radiusUnits,
                                    'transform':'translate3d(0,'+ Math.round(relativeTop * config.spacing[0] / 100)+'px , 0)'})
                    else
                        middle.css({transform:'translate3d(0,'+ Math.round(relativeTop * config.spacing[0] / 100)+'px , 0)'})

                    front.css({"border-top-right-radius":config.hradius + " " + Math.round(relativeTop * config.radius[2] / 100)  + config.radiusUnits,
                                'transform':'translate3d(0,'+ Math.round(relativeTop * config.spacing[1] / 100)+'px , 0)'})

                } else {
                    if(config.radius[0])
                        back.css({"border-top-left-radius":config.hradius + " " + Math.round(relativeTop * config.radius[0] / 100)  + config.radiusUnits})

                    if(config.radius[1])
                        middle.css({'border-top-left-radius':config.hradius + " " + Math.round(relativeTop * config.radius[1] / 100)  + config.radiusUnits,
                                    'transform':'translate3d(0,'+ Math.round(relativeTop * config.spacing[0] / 100)+'px , 0)'})
                    else
                        middle.css({transform:'translate3d(0,'+ Math.round(relativeTop * config.spacing[0] / 100)+'px , 0)'})

                    front.css({'border-top-left-radius':config.hradius + " " + Math.round(relativeTop * config.radius[2] / 100)  + config.radiusUnits,
                               'transform':'translate3d(0,'+ Math.round(relativeTop * config.spacing[1] / 100)+'px , 0)'})
                }


            })

        })

    }
})(jQuery);