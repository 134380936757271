(function() {
    $.fn.quickActions = function(config) {

        var config = $.extend({
            cls: 'open',
            globalCls: 'quick-actions-opened',
            tab: $('.quick-actions .tab-bar a[role="tab"]'),
            trigger: 'closing-btn'
        },config);

        return this.each(function () {

            var $el = $(this),
                $tab = config.tab;
            
            $tab.on('click', function () {

                if (!$el.hasClass(config.cls)) {
                    $el.addClass(config.cls);
                    $(this).addClass(config.trigger);
                    $('body').addClass(config.globalCls);
                } else if ($(this).hasClass(config.trigger)) {
                    $el.removeClass(config.cls);
                    $('body').removeClass(config.globalCls);
                    $(this).removeClass(config.trigger);
                }

                $('.quick-actions [data-toggle="tooltip"]').tooltip('hide');

            });

            $tab.on('shown.bs.tab', function (e) {
                config.tab.filter("." + config.trigger).removeClass(config.trigger);
                $(e.target).addClass(config.trigger);
            });


            //ajax
            $tab.filter('[data-ajax]').on("click", function () {
                var $this = $(this),
                    $target = $($(this).attr("href"));

                if ($target.hasClass('.js-loaded'))
                    return

                $.ajax({
                    url: $(this).data('ajax'),
                    async: true,
                    success: function (r) {
                        $target.html(r);
                        $target.addClass("js-loaded");
                    }
                });
            });


        });



    }
})(jQuery);