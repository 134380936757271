(function() {
    $.fn.triggerBtn = function(config) {

        var config = $.extend({
            target: null
        },config);

        return this.each(function(){

            var $btn = $(this),
                $target= $($(this).attr('href')) || $(config.target);

            $btn.on('click', function(){

                $target.trigger("click");
                return false

            });

        });
    }
})(jQuery);