$(function () {

    initFormValidation();
    initAjaxTabs();

    //$(document).pjax('a', 'body', { fragment: 'body' });

    function initFormValidation() {
        jQuery.validator.setDefaults({
            highlight: function (element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).addClass(errorClass).removeClass(validClass);
                    $(element).closest('.form-group').removeClass('has-success').addClass('has-error');
                }
            },
            unhighlight: function (element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).removeClass(errorClass).addClass(validClass);
                    $(element).closest('.form-group').removeClass('has-error').addClass('has-success');
                }
            }
        });
    }

    function initAjaxTabs() {
        $('[data-toggle="tabajax"]').click(function (e) {
            var $this = $(this),
                loadurl = $this.attr('href'),
                targ = $this.attr('data-target');

            $.get(loadurl, function (data) {
                $(targ).html(data);
            });

            $this.tab('show');
            //$this.trigger('shown.bs.tab');
            return false;
        });
    };
});

var App = (function () {
    var contact = null;

    return {
        mailTargets: {
          office: 0,
          support: 1,
          license: 2
        },

        contact: function (subject, message, target) {
          if (!target)
            target = 0;

          if (subject) {
            contact = {
                subject: subject,
                message: message,
                target: target,
                themeTrial: subject.indexOf('Theme Trial') != -1
            };
          } else {
            contact = null;
          }

          $('#quick-contact-tab').click();
          return false;
        },

        getContactData: function () {
          return contact;
        }
    }
})();
