//required : bootstrap transition.js

(function() {
    $.fn.slidePain = function(config) {

        var config = $.extend({
            blurBackground: '.e-slider-slide'
        },config);

        function setBlurWidth(elem){
            var target = $(config.blurBackground)

            if (target.length == 0 || elem.length == 0)
                return

            var left = elem.outerWidth()
            target.css("padding-left", left + "px")

            console.log(target)
            console.log(left)
        }

        return this.each(function(){

            var nav = $(this)

            setBlurWidth($(nav.find('a.s-active').attr("href")))


            $(this).on("click", 'a', function () {

                var target = $($(this).attr("href")),
                    link = $(this),
                    activeTarget = target.siblings('.s-active')

                if (!link.hasClass('s-active')) {

                    activeTarget
                        .addClass('s-closing')
                        .add(nav.find('.s-active'))
                        .removeClass('s-active')

                    target
                        .add(link)
                        .addClass('s-active')

                    setBlurWidth(target)

                    //bootstrap on transition end event
                    activeTarget.on($.support.transition.end,function(){
                        activeTarget.removeClass('s-closing')
                    })
                }

                return false

            })

        })
    }
})(jQuery);