(function() {
    $.fn.pageNav = function(settings) {

        var settings = $.extend({
            activeCls: 'active',
            btn: '.js-nav-btn',
            nextBtn: '.js-next',
            nextBtnModifierCls: 'to-top',
            middleOffset: 90,
            scrollEl: window,
            nav: 'nav.sub',
            mobileWidthBreakpoint : 992
        },settings);

        var _scrolling = false,
            _height = $(settings.nav).find('li:first-child').height(),
            _step = $(settings.nav).find('li:nth-child(2)').height(),
            _elObj = this


        //convert next btn from the last element in to back to top
       $(_elObj.get(this.length - 1))
           .find(settings.nextBtn)
           .addClass(settings.nextBtnModifierCls);


        return this.each(function(index){

            var $this = $(this);

            var $el = {
                section: $(this),
                btn: (function(){

                    var id = $this.attr("id");

                    //if there is not corresponding btn in navigation add fake one ( for localScroll plugin )
                    if ($(settings.nav + ' ' + 'a[href="#' + id + '"]').length === 0) {
                        $(settings.nav).append('<a href="#' + id + '" style="display: none" class="ghost-btn '+settings.btn+'"></a>')
                    }

                    //select btn
                    var btn = $(settings.nav).find('a[href="#' + id + '"]');

                    //add btn to corresponding section data attribute for easy access
                    $this.data('trigger-btn',btn);

                    return btn

                })(),
                nextBtn: $(this).find(settings.nextBtn)
            }



            //attaching custom event on navigation btn for active state (active btn)
            $el.btn.on('activate',function (){

                //skip for already active btn and ghost btn
                if($(this).hasClass('ghost-btn') || $(this).hasClass(settings.activeCls))
                    return

                //reset and add active class
                $(settings.nav).find('li.' + settings.activeCls).removeClass(settings.activeCls);
                $(this).parent('li').addClass(settings.activeCls);

                //setting height for top spacer => changing position for active btn
                var aboveEl = $(this).parent('li').prevAll().length;
                $(settings.nav).find('li:first-child').height( _height - ( _step * (aboveEl - 1) ))

            });


            //triggering active event on navigation btn
            $el.btn.on("click",function(){
                _scrolling = true;
                clearTimeout($.data(this, 'scrollTimer'));
                $(this).trigger("activate");
            })

            //trigger "trigger-btn" on the next section or on the first section ( back to top )
            $el.nextBtn.on("click",function(){
                var btn;

                if (!$(this).hasClass('to-top'))
                    btn = $(_elObj.get(index + 1)).data("trigger-btn");
                else
                    btn = $(_elObj.get(0)).data("trigger-btn");

                btn.trigger("click");
                return false
            });


            //colling jquery.localScroll plugin
            $(settings.nav).localScroll({
                target:settings.scrollEl,
                onAfter: function() { _scrolling = false }
            });


            //listener for scrolling
            $(settings.scrollEl).on("scroll",function(){

                //skip jquery.localScroll scroll
                if(_scrolling)
                    return

                //recording active section to data attribute of scrollEl and triggering activate event on sub navigation button
                $el.section.each(function () {

                    var top = settings.scrollEl != window ? $(this).offset().top : $(this).offset().top - $(window).scrollTop();

                    if( top > $(settings.scrollEl).height() - 5 || top < -5 )
                        return

                    $(settings.scrollEl).data("activeSection",$(this));


                    if( top < $(settings.scrollEl).height() / 2) {

                        if(!$(settings.scrollEl).data("activeSection").data("trigger-btn").closest("li").hasClass("active"))
                            $(settings.scrollEl).data("activeSection").data("trigger-btn").trigger('activate')

                    } else {

                        if(!$(settings.scrollEl).data("activeSection").data("trigger-btn").closest("li").prev().hasClass("active"))
                            $(settings.scrollEl).data("activeSection").data("trigger-btn").closest("li").prev().find("a").trigger('activate')
                    }

                });

                //auto scroll
                clearTimeout($.data(this, 'scrollTimer'));

                $.data(this, 'scrollTimer', setTimeout(function() {

                    if(_scrolling || $(window).width() < settings.mobileWidthBreakpoint)
                        return

                    var $activeSection = $(settings.scrollEl).data("activeSection"),
                        top = settings.scrollEl != window ? $($activeSection).offset().top : $($activeSection).offset().top - $(window).scrollTop(),
                        btn = $activeSection.data("trigger-btn");

                    if( top < $(settings.scrollEl).height() / 2) {

                        btn.trigger('click');

                    } else {

                        if (btn.hasClass("ghost-btn")) {
                            btn.prev().trigger('click')
                        } else {
                            btn.closest("li").prev().find(settings.btn).trigger('click');
                        }

                    }


                }, 1000));

            });





        });
    }
})(jQuery);