(function() {
    $.fn.switch = function(config) {

        var config = $.extend({
            cls: 'open',
            closedBtnCls: 'pressed',
            target: null,
            auto: false
        },config);

        return this.each(function(){

            var $btn = $(this),
                $target= $($(this).attr('href')) || $(config.target),
                cssConfig = ($(this).parent().css('font-family')).replace(/['"]+/g, '').split('.');

            var cls = cssConfig[0] === 'config' ? cssConfig[1] : config.cls,
                auto = cssConfig[0] === 'config' && cssConfig[2] === 'auto' ? true : config.auto;

            $btn.on('click', function(){

                if ($target.hasClass('open')) {
                    $target.removeClass('open').addClass('closed');
                    $(this).addClass(config.closedBtnCls);
                } else if ($target.hasClass('closed')) {
                    $(this).removeClass(config.closedBtnCls);
                    $target.removeClass('closed').addClass('open');
                } else {
                    $target.addClass(cls);
                    if (cls == "closed")
                        $(this).addClass(config.closedBtnCls);
                    else
                        $(this).removeClass(config.closedBtnCls);
                }

                return false

            });


            if(auto) {
                setTimeout(function(){
                    $btn.trigger("click");
                },1500);
            }


        });
    }
})(jQuery);